(function () {

    'use strict';

    const STATES = [
        {
            "name": "last_prescribed",
            "label": "states_medicine_last_prescribed",
        }
    ];

    const {BehaviorSubject} = require("rxjs");

    class MedicineReport {
        constructor(genericStatesService, system, $translate, $scope, $state, $stateParams, moment) {
            this.genericStatesService = genericStatesService;
            this.system = system;
            this.translate = $translate;
            this.scope = $scope;
            this.$state = $state;
            this.moment = moment;
            this.dateFormat = system['date_format'].js;

            this.currentState = genericStatesService.getCurrentState("medicine_report");

            this.isChart = false;
            this.states = STATES;
            this.activeState = _.get(this.states, '0');


            this.query = {
                start: $stateParams.start || this.moment().subtract(3, "months").startOf('month').format(this.dateFormat),
                end: $stateParams.end || this.moment().endOf('month').format(this.dateFormat),
            };

            this.headers = {
                last_prescribed: {
                    _id: {
                        type: "number",
                        caption: this.translate.instant('unique_id')
                    },
                    patient_id: {
                        type: "number",
                        caption: this.translate.instant('unique_id')
                    },
                    medicine_id: {
                        type: "number",
                        caption: this.translate.instant('unique_id')
                    },
                    patient_fullname: {
                        type: "string",
                        caption: this.translate.instant('dcm_patient_full_name')
                    },
                    medicine_name: {
                        type: "string",
                        caption: this.translate.instant('commercial_name')
                    },
                    medicine_name_sum: {
                        type: "string",
                        caption: this.translate.instant('states_medicine_commercial_name_sum')
                    },
                    prescription_date: {
                        type: "date string",
                        caption: this.translate.instant('exam_date')
                    }
                }
            };
            this.$dataSource = new BehaviorSubject({
                slice: {},
                data: [],
                headers: {},
                onlyData: false
            });
        }

        static get $inject() {
            return ["genericStatesService", "system", "$translate", "$scope", "$state", "$stateParams", "moment"];
        }

        $onInit() {
            this.maxDate = this.moment().add(9, "months").toDate();
            this.getData();
        }


        getSlice() {
            return {
                "expands": {
                    "expandAll": false,
                    "rows": [
                        {"tuple": []}
                    ]
                },
                "drills": {
                    "drillAll": false
                },
                "rows": this.getRows(),
                "columns": this.getColumns(),
                "measures": this.getMeasures()
            };
        }


        getRows() {
            if (this.activeState.name === 'last_prescribed')
                return [
                    {
                        "uniqueName": "medicine_name"
                    },
                    {
                        "uniqueName": "prescription_date"
                    },
                    {
                        "uniqueName": "patient_fullname"
                    }
                ]
        }

        getColumns() {
            if (this.activeState.name === 'last_prescribed')
                return [
                    {
                        "uniqueName": "Measures"
                    }
                ];
        }


        getMeasures() {
            if (this.activeState.name === 'last_prescribed')
                return [
                    {
                        "uniqueName": "medicine_name_sum",
                        "individual": false,
                        "format": "integer",
                        "formula": "count(\"medicine_name\")",
                    }
                ]
        }

        getData(onlyData = false) {
            this.promise = this.genericStatesService.getMedicineReport(
                _.assign(this.query, {data_source: this.activeState.name})
            ).then(data => {
                if (onlyData) {
                    this.$dataSource.next({
                        onlyData: true,
                        data: data,
                    });
                } else {
                    this.$dataSource.next({
                        slice: this.getSlice(),
                        data: data,
                        headers: this.headers[this.activeState.name]
                    });
                }
            });
        }

        handleFileName() {
            const start = moment(this.query.start, this.dateFormat).format(this.genericStatesService.exportDateFormat);
            const end = moment(this.query.end, this.dateFormat).format(this.genericStatesService.exportDateFormat);

            return `${this.translate.instant('states_medicine_file_name', {start, end})}`;
        }

        handleStatesChange() {
            this.scope.$applyAsync(() => this.queryChanged(false));
        }

        queryChanged(onlyData = true) {
            const start = moment(this.query.start, this.dateFormat);
            const end = moment(this.query.end, this.dateFormat);

            if (start.isAfter(end)) {
                this.query.end = start.add(3, "months").endOf('month').format(this.dateFormat);
            }

            this.$state.transitionTo(
                this.$state.current.name,
                this.query,
                {notify: false}
            ).then(() => this.getData(onlyData));
        }

        onComplete(instance) {
        }

        customizeCells(cellBuilder, cellData) {
        }
    }

    module.exports = MedicineReport;
})();